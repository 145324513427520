<template>
    <div>
        <el-empty v-if="list.length == 0" description="空空如也~"></el-empty>
        <el-row v-else>
            <el-col v-for="(m, index) in list">
                <el-skeleton :loading="loading" animated :count="1">
                    <template slot="template">
                        <div class="bottom flex-bt">
                            <div class="left">
                                <el-skeleton-item variant="text" style="width: 30%;" />
                                <el-skeleton-item variant="text" style="width: 30%;" />
                                <el-skeleton-item variant="text" style="width: 30%;" />
                            </div>
                            <div>
                                <el-skeleton-item variant="text" style="width: 30%;" />
                                <el-skeleton-item variant="text" style="width: 30%;" />
                            </div>
                        </div>
                    </template>
                    <template>
                        <div class="list">
                            <div class="content">{{m.content}}</div>
                            <div class="time-wrap">
                                <p class="price">
                                    <span class="money">+{{ m.money }}</span>
                                </p>
                                <p class="time">{{ m.time }}</p>
                            </div>
                        </div>
                    </template>
                </el-skeleton>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        name: 'incomeList',
        components: {},
        props: ['list'],
        data() {
            return {
                loading: false,
            };
        },
        created() {
            // 在组件实例处理完所有与状态相关的选项后调用。
        },
        mounted() {
            // 在组件被挂载之后调用。
        },
        methods: {},
        computed: {
            // 计算属性
        },
        watch: {
            // 监听
        }
    };
</script>

<style lang="less" scoped>
    .list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        border-bottom: 1px solid #eee;

        .content {
            flex: 1;
            font-size: 14px;
            margin-right: 10px;
        }

        .time-wrap {
            text-align: right;
            font-size: 12px;

            .price {
                margin-bottom: 5px;
                color: orange;

                .money {
                    font-size: 20px;
                    color: orange;
                }


            }

            .time {
                color: #999;
            }
        }
    }
</style>