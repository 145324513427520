<template>
    <div>
        <ul class="ul-wrap">
            <incomeList :list="list" v-infinite-scroll="getData"></incomeList>
            <p class="more-btn" v-if="list.length">{{(total==-1 || list.length % 15 !=0 ) ? '暂无更多':'加载中'}}</p>
        </ul>
    </div>
</template>

<script>
    import incomeList from "@components/common/income-list";
    import {
        mapGetters,
        mapMutations
    } from 'vuex';
    export default {
        components: {
            incomeList
        },
        data() {
            return {
                list: [],
                total: 0
            };
        },
        created() {
            // 在组件实例处理完所有与状态相关的选项后调用。

        },
        mounted() {
            // 在组件被挂载之后调用。
            this.getData(1);
        },
        methods: {
            // ...mapMutations([])
            // 获取列表
            async getData(first) {
                let $r = this.$route.query;
                let param = {
                    cate: $r.cate || 0,
                    pagesize: 15
                };
                if (first) {
                    param.page = 1;
                }
                let {
                    list,
                    total
                } = await this.$y_list('api/member/teamIncomeLists', this.list, param);
                this.list = list;
                this.total = total;
            },
        },
        computed: {
            // 计算属性
            // ...mapGetters([])
        },
        watch: {
            // 监听
            $route() {
                this.getData(1);
            },
        }
    };
</script>

<style lang="less" scoped>
    .more-btn {
        text-align: center;
        color: #aaa;
        padding: 15px 0;
    }
</style>